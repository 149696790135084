import Benefite from './Benefite/Benefite'
import './CourseBenefits.css'

function CourseBenefits({isManSide, benefiteCourseArr, isNewFormat, courseName}) {
    return (
        <section className={`course__benefits ${isNewFormat ? "new" : ""}`}>
            {
                isNewFormat ? 
                <h2 className={`course__benefits-title ${isNewFormat ? "new" : ""} text-title-one ${isManSide ? "" : "w"}`}>
            Кому <span className={isManSide ? "man-color" : "woman-color"}>{courseName}</span> підійде
            </h2> :   <h2 className={`course__benefits-title text-title-one ${isManSide ? "" : "w"}`}>
            Плюшки після <span className={isManSide ? "man-color" : "woman-color"}>Курсу</span>
            </h2>
            }
          
            <div className={`course__benefits-wrapper ${isNewFormat ? "new" : ""}`}>
                {benefiteCourseArr.map((benefite, idx) => {
                    return (
                        <Benefite isManSide={isManSide} key={idx} benefiteNumber={benefite.number} benefiteTitle={benefite.title} isNewFormat={isNewFormat} benefiteDescription={benefite.description}/>
                    )
                })}
            </div>
        </section>
    )
}

export default CourseBenefits