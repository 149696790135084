export const deepSoulCardsArr = [
    {
        title: "Basic Package",
        include: ["Доступ до уроків модулю “Basic” назавжди", "Збірник «Гайд по сексу» на 34 сторінки", "Закрите комʼюніті з однодумцями",],
        notInclude: ["Доступ до уроків модулю “PRO” назавжди", "Звʼязок з кураторами 24/7", "Доступ до уроків модулю “VIP” назавжди","Доступ до ексклюзивних вебінарів","Можливість потрапити на офлайн практику",],
        lessonsCount: "8 відео-уроків",
        price: "145$",
        href: "https://t.me/hub1317_admin",
    },
    {
        title: "PRO Package",
        include: ["Доступ до уроків модулю “Basic” назавжди", "Збірник «Гайд по сексу» на 34 сторінки", "Закрите комʼюніті з однодумцями","Доступ до уроків модулю “PRO” назавжди","Звʼязок з кураторами 24/7"],
        notInclude: ["Доступ до уроків модулю “VIP” назавжди", "Доступ до ексклюзивних вебінарів", "Можливість потрапити на офлайн практику",],
        lessonsCount: "12 відео-уроків",
        price: "320$",
        href: "https://t.me/hub_1317_admin",
    },
    {
        title: "VIP Package",
        include: ["Доступ до уроків модулю “Basic” назавжди", "Збірник «Гайд по сексу» на 34 сторінки", "Закрите комʼюніті з однодумцями","Доступ до уроків модулю “PRO” назавжди","Звʼязок з кураторами 24/7","Доступ до уроків модулю “VIP” назавжди","Доступ до ексклюзивних вебінарів","Можливість потрапити на офлайн практику"],
        notInclude: [],
        lessonsCount: "23 відео-уроки",
        price: "650$",
        href: "https://t.me/admn1317",
    },
]