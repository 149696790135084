import './Benefite.css'

function Benefite({benefiteTitle, benefiteDescription, benefiteNumber, isManSide, isNewFormat}) {
    return (
        <div className="course__benefite">
            <div className={`course__benefite-number ${isNewFormat ? "new" : ""} text-title-one ${isManSide? "man-color" : "woman-color"}`}>{benefiteNumber}</div>
            <div className="course__benefite-texts">
            <div className="course__benefite-header">
            <div className={`course__benefite-number-mobile ${isNewFormat ? "new" : ""} text-title-one ${isManSide? "man-color" : "woman-color"}`}>{benefiteNumber}</div>

                <h2 className={`course__benefite-title text-title-two ${isManSide ? "" : "w"}`}>{benefiteTitle}</h2>
            </div>
            <p className="course__benefite-description body-text">{benefiteDescription}</p>
            </div>
        </div>
    )
}

export default Benefite