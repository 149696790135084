import ForWhoSection from "../../components/ForWhoSection/ForWhoSection";
import CourseAbout from "./CourseAbout/CourseAbout";
import CourseBenefits from "./CourseBenefits/CourseBenefits";
import CourseCards from "./CourseCards/CourseCards";
import "./CoursePage.css";
import CoursePageCover from "./CoursePageCover/CoursePageCover";
import CourseProgram from "./CourseProgram/CourseProgram";

function CoursePage({
  urlCover,
  aboutUrl,
  aboutTextFirst,
  aboutTextSecond,
  aboutTitle,
  courseName,
  isManSide,
  courseArr,
  willLernArr,
  price,
  isNewFormat,
  coverTitle,
  courseProgramArr,
  courseCardsArr,
}) {
  return (
    <div className="course__page">
      <CoursePageCover
        coverTitle={coverTitle}
        isNewFormat={isNewFormat}
        urlCover={urlCover}
      />
      <CourseAbout
        isNewFormat={isNewFormat}
        price={price}
        willLernArr={willLernArr}
        aboutUrl={aboutUrl}
        isManSide={isManSide}
        aboutTextFirst={aboutTextFirst}
        aboutTextSecond={aboutTextSecond}
        aboutTitle={aboutTitle}
      />
      {isNewFormat ? (
        <ForWhoSection
          isCoursePage={true}
          courseName={courseName}
          isManSide={isManSide}
          forWhoArray={courseArr.forWho}
          isNewFormat={isNewFormat}
        />
      ) : (
        <CourseBenefits
          isManSide={isManSide}
          courseName={courseName}
          isNewFormat={isNewFormat}
          benefiteCourseArr={courseArr.benefiteCourseArr}
        />
      )}
    {
        isNewFormat && <CourseProgram courseProgramArr={courseProgramArr} isManSide={isManSide} />
    }
    {
        isNewFormat && <CourseCards courseCardsArr={courseCardsArr} isManSide={isManSide} />
    }
 

      {isNewFormat ? (
        <CourseBenefits
          isManSide={isManSide}
          isNewFormat={isNewFormat}
          courseName={courseName}
          benefiteCourseArr={courseArr.benefiteCourseArr}
        />
      ) : (
        <ForWhoSection
          isCoursePage={true}
          courseName={courseName}
          isManSide={isManSide}
          forWhoArray={courseArr.forWho}
          isNewFormat={isNewFormat}
        />
      )}
    </div>
  );
}

export default CoursePage;
