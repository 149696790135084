export const deepSoulProgramArr = [
    {
        title: "Модуль 1: Basic",
        subtitle: "7 Уроків: Ази порно мінету зібрані в одному місці. Без зайвої води - чітко і по ділу.",
        modules: [
            {
                courseCount: "1",
                courseName: "Рвотний рефлекс ",
                text: "- що це і навіщо? Пояснюємо які психологічні та фізичні фактори його викликають",
            },
            {
                courseCount: "2",
                courseName: "Долаємо рвотний ",
                text: "- як переступити через ментальний барʼєр, який не дає тобі взяти глибоко в глотку?",
            },
            {
                courseCount: "3",
                courseName: "Практичні вправи від Тіни Кросс, ",
                text: "що допоможуть прибрати рвотний рефлекс, не залежно від розміру та часу ",
            },
            {
                courseCount: "4",
                courseName: "Техніка дихання ",
                text: "- як заспокоїти свій мозок та контролювати подачу кисню? Практика вільного дихання.",
            },
            {
                courseCount: "5",
                courseName: "Не ігноруй яйця ",
                text: "- це найчутливіша зона! Як працювати з його мошонкою, поки глотка зайнята членом?",
            },
            {
                courseCount: "6",
                courseName: "Топ пози для глибокої глотки. ",
                text: "Як робити мінет в будь-якому положенні, з урахуванням вигину члена та його розміру.",
            },
            {
                courseCount: "7",
                courseName: "Діалоги під час мінету ",
                text: "- як і що сказати під час, аби змусить його закінчити так глибоко в твою глотку, як ніколи.",
            },
        ],
    },
    {
        title: "Модуль 2: PRO",
        subtitle: "8 Уроків: Підкорюйте не лише його тіло, а й вчіться керувати його мозком!",
        modules: [
            {
                courseCount: "1",
                courseName: "Чутливість члена ",
                text: "як визначити і чому  це база для його оргазмів? Гайд по збору інформації на окремих зонах його тіла.",
            },
            {
                courseCount: "2",
                courseName: "Психологічні травми, ",
                text: "що впливають на ваше сексуальне життя: практика від сексолога, щоб він зміг з тобою кінчати",
            },
            {
                courseCount: "3",
                courseName: "Масаж лінгама ",
                text: "- усі техніки, як довести його до піку не лише глоткою, а й за допомогою рук під час мінету",
            },
            {
                courseCount: "4",
                courseName: "Заволодій мозком, ",
                text: "а не лише членом - прокачай жіночу домінацію та впевненість, аби він не мав шансу не підкоритися",
            },
            {
                courseCount: "5",
                courseName: "Порно мінет ",
                text: "- влаштуй йому оральне шоу, після якого ти точно відчуєш, що таке вдячний шанувальник",
            },
            {
                courseCount: "6",
                courseName: "Сходіть у секс шоп, ",
                text: "аби розпалити уяву до якісного сексу. Поради по засобам та девайсам для професійного мінету. ",
            },
            {
                courseCount: "7",
                courseName: "Не втрачай лібідо ",
                text: "- як смоктати член у своє задоволення? Чому варто полюбити мінет, а не смоктати «бо він захотів»",
            },
            {
                courseCount: "8",
                courseName: "Домінуй на колінах ",
                text: "- техніка підкорення чоловіка. Тут зона твого контролю - тож бери в руки не лише член, а й процес",
            },
        ],
    },
    {
        title: "Модуль 3: VIP",
        subtitle: "16 Уроків: Готуйтесь – вам поклонятимуться! Маючи усі ці знання, ви станете тою самою ФАТАЛЬНОЮ ЖІНКОЮ!",
        modules: [
            {
                courseCount: "1",
                courseName: "ЖМЖ та МЖМ ",
                text: "- секрети орального сексу втрьох! Як не розгубитись? Знання про усі види пестощів.",
            },
            {
                courseCount: "2",
                courseName: "Мінет випускниці ",
                text: "- офлайн навчання з випускницею курсу. Працюємо над глибиною та техніками в режимі лайф",
            },
            {
                courseCount: "3",
                courseName: "Відвертий влог ",
                text: "- вчимо дівчину глибокому мінету з нуля . Розберемо усі труднощі перших заглотів та помилки",
            },
            {
                courseCount: "4",
                courseName: "Сценарії сексу, ",
                text: "які дозволять забути, що таке рутинний відсос… Нові формати - це антиген звиканню",
            },
            {
                courseCount: "5",
                courseName: "Ти не запасна! ",
                text: "Як не відчувати себе зайвою та бути завжди бажаною жінкою для нього під час сексу втрьох.",
            },
            {
                courseCount: "6",
                courseName: "Стань головною жінкою ",
                text: "у його житті та  впевнено йди на секс експерименти! Втілюй усі фантазії від тріо до оргії.",
            },
            {
                courseCount: "7",
                courseName: "Влаштуй шоу: ",
                text: "як бути в сексі впевненою, сильною та жіночною. Вчимось стогнати, рухатись та приймати себе",
            },
            {
                courseCount: "8",
                courseName: "Ідеальний ракурс: ",
                text: "як у сексі виглядати завжди якнайкраще? Забудь про сором і ти більше не вимикатимеш світло",
            },
            {
                courseCount: "9",
                courseName: "Ти будеш єдиною. ",
                text: "Зроби його залежним від себе, і після згадки про вашу ніч, він знайде усі способи, аби це повторити",
            },
            {
                courseCount: "10",
                courseName: "Керуй його фетишами. ",
                text: "Дізнайся та втіль правильно його фантазії, щоб це не змогла зробити інша. Техніка «анти зрада»",
            },
            {
                courseCount: "11",
                courseName: "Ефект ЖМЖ  без третьої ",
                text: "- як за допомогою мастурбатора і повʼязки на очах чоловіка,  створити ефект сексу втрьох?",
            },
            {
                courseCount: "12",
                courseName: "Масаж члена, ",
                text: "від якого його ніжки затрусяться і ти почуєш «я такого ще не відчував» - техніка від Тіни Кросс",
            },
            {
                courseCount: "13",
                courseName: "Королівський мінет ",
                text: "- техніка з максимальним заглотом по яйця. Просунутий рівень глибокого - до самого корінця.",
            },
            {
                courseCount: "14",
                courseName: "Він стогнатиме ",
                text: "- вчимо чоловіка кінчати гучно і стираємо психологічні барʼєри, які не дають йому насолоджуватись.",
            },
            {
                courseCount: "15",
                courseName: "Слоу мо мінет ",
                text: "- техніка, яка змусить його член тремтіти від задоволення. Формат, що переверне сприйняття мінету",
            },
            {
                courseCount: "16",
                courseName: "Втрата лібідо ",
                text: "- в чому причина зниження твого лібідо? Працюємо над поверненням і його підвищенням.",
            },
        ],
    },
    
]