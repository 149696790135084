import CourseCard from '../../../components/CourseCard/CourseCard'
import './CourseCards.css'

const CourseCards = ({courseCardsArr, isManSide}) => {
    console.log(courseCardsArr);
  return (
    <section className='course__cards-section'>
        <h2 className={`text-title-one ${isManSide ? "" : "w"}`}>Формати навчання</h2>
    <div className="course__cards-wrapper">
        <CourseCard isManSide={isManSide} item={courseCardsArr[0]} isCheap={true}/>
        <CourseCard  isManSide={isManSide}item={courseCardsArr[1]} isMiddle={true}/>
        <CourseCard isManSide={isManSide} item={courseCardsArr[2]} isExpencive={true}/>
    </div>
    </section>
  )
}

export default CourseCards