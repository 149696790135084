import './CourseProgram.css'
import ProgramAccordion from './ProgramAccordion/ProgramAccordion'

const CourseProgram = ({courseProgramArr, isManSide}) => {
  return (
    <div className='course__program'>
        <h2 className={`${isManSide ? "text-title-one" : "text-title-one w"}`}>Програма курсу</h2>
        <ProgramAccordion courseProgramArr={courseProgramArr} isManSide={isManSide}/>
    </div>
  )
}

export default CourseProgram