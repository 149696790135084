import './CourseCard.css'
import starImage from '../../assets/icons/starImage.png'

const CourseCard = ({isCheap, isMiddle, isExpencive, item, isManSide }) => {
  return (
    <div className={`course__card ${isMiddle ? "middle" : isExpencive ? "expencive" : ""}`}>
        <div className={`course__card-decoration ${isManSide ? "" : "w"} body-text`}>{item.lessonsCount}</div>
        <h3 className={`course__card-title ${isMiddle ? "middle" : isExpencive ? "expencive" : ""} text-title-two ${isManSide ? "" : "w"}`}>{item.title} <img className={`star__icon ${isExpencive ? "visible" : "hidden"}`} src={starImage} alt="star image" /></h3>
        {
            item.include.map((e,idx) => {
                return (
                    <p key={idx} className="course__card-include body-text">{e}</p>
                )
            })
        }
        {
            item.notInclude.map((e,idx) => {
                return (
                    e &&
                    <p key={idx} className="course__card-notinclude body-text">{e}</p>
                    
                )
            })
        }
    <div className="course__card-border"></div>
    <a target={"_blank"} rel={"noreferrer"} href={item.href} className={`course__card-link ${isManSide ? "" : "w"} text-title-two ${isManSide ? "" : "w"}`}>Придбати курс</a>
    <p className={`course__card-price ${isManSide ? "" : "w"} text-title-two ${isManSide ? "" : "w"}`}>{item.price}</p>
    </div>
  )
}

export default CourseCard